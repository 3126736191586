import Image from 'next/image';

export const SocialMediaIcons = () => {
  return (
    <div className="flex gap-4 md:gap-3">
      {' '}
      <a
        href="https://www.instagram.com/seesighttours"
        target="_blank"
        rel="noreferrer noopener"
        aria-label="This is an external link (opens in a new tab)"
      >
        <Image
          src={
            'https://res.cloudinary.com/see-sight-tours/image/upload/v1713263648/f6jqxiglvzal2dafglqj.svg'
          }
          width={38}
          quality={100}
          height={38}
          alt="Instagram"
        />
      </a>
      <a
        href="https://www.facebook.com/seesighttours"
        target="_blank"
        rel="noreferrer noopener"
        aria-label="This is an external link (opens in a new tab)"
      >
        <Image
          src={
            'https://res.cloudinary.com/see-sight-tours/image/upload/v1713263647/qymiqavkrqdhr5yhtkdx.svg'
          }
          width={38}
          quality={100}
          height={38}
          alt="Facebook"
        />
      </a>
      <a
        href="https://ca.pinterest.com/seesighttours/"
        target="_blank"
        rel="noreferrer noopener"
        aria-label="This is an external link (opens in a new tab)"
      >
        <Image
          src={
            'https://res.cloudinary.com/see-sight-tours/image/upload/v1713263647/wrb0blpujbh0lklymtqc.svg'
          }
          width={38}
          quality={100}
          height={38}
          alt="Pinterest"
        />
      </a>
      <a
        href="https://x.com/seesighttours"
        target="_blank"
        rel="noreferrer noopener"
        aria-label="This is an external link (opens in a new tab)"
      >
        <Image
          src={
            'https://res.cloudinary.com/see-sight-tours/image/upload/v1713263647/idhwbmmwevpliasgagg8.svg'
          }
          width={38}
          quality={100}
          height={38}
          alt="Twitter"
        />
      </a>
      <a
        href="https://www.youtube.com/@seesight-tours"
        target="_blank"
        rel="noreferrer noopener"
        aria-label="This is an external link (opens in a new tab)"
      >
        <Image
          src={
            'https://res.cloudinary.com/see-sight-tours/image/upload/v1713263646/m3ltnz8zck5oqotitamu.svg'
          }
          width={38}
          quality={100}
          height={38}
          alt="YouTube"
        />
      </a>
      <a
        href="https://www.linkedin.com/company/see-sight-tours-ca/"
        target="_blank"
        rel="noreferrer noopener"
        aria-label="This is an external link (opens in a new tab)"
      >
        <Image
          src={
            'https://res.cloudinary.com/see-sight-tours/image/upload/v1713263647/msnxb1efmgranonrfzlw.svg'
          }
          width={38}
          quality={100}
          height={38}
          alt="LinkedIn"
        />
      </a>
      <a
        href="https://www.tiktok.com/@seesighttours"
        target="_blank"
        rel="noreferrer noopener"
        aria-label="This is an external link (opens in a new tab)"
      >
        <Image
          src={
            'https://res.cloudinary.com/see-sight-tours/image/upload/v1738138288/Tiktok_Icon_SVG_1_am2mod.svg'
          }
          width={38}
          quality={100}
          height={38}
          alt="TikTok"
        />
      </a>
    </div>
  );
};
