import Image from 'next/image';
import { useMediaQuery } from '../../../hooks/mediaQuery';

interface ShowMapToggleButtonProps {
  showFullMap: boolean;
  toggleSection: () => void;
  hits: any[];
}

export const ShowMapToggleButton: React.FC<ShowMapToggleButtonProps> = ({
  showFullMap,
  toggleSection,
  hits
}) => {
  const isMobileScreen = useMediaQuery(500);

  const generateButtonClasses = (
    showFullMap: boolean,
    hits: any[],
    scrollY: number
  ) => {
    let positionClasses = '';

    if (hits.length === 1 && scrollY > 10 && isMobileScreen) {
      positionClasses = 'bottom-[9rem]';
    } else if (showFullMap) {
      positionClasses = 'bottom-[2.5rem]';
    } else {
      positionClasses =
        '4xl:bottom-[5rem] 3xl:bottom-[5rem] 2xl:bottom-[4.5rem] sm:bottom-[4.5rem] bottom-[6rem]';
    }

    const zIndexClasses = showFullMap ? 'z-[9999]' : 'z-[38]';

    return `fixed ${positionClasses} left-1/2 transform -translate-x-1/2 transition-all duration-500 ease-in-out ${zIndexClasses}`;
  };
  return (
    <div className={generateButtonClasses(showFullMap, hits, window?.scrollY)}>
      <button
        className="text-white text-base leading-[18px] px-5 py-3 rounded-full flex items-center h-[42px] bg-primary hover:bg-[#2E3D7A] active:bg-[#2E3D7A] "
        onClick={toggleSection}
      >
        <Image
          src={!showFullMap ? '/map-icon.svg' : '/list-icon.svg'}
          alt="map-icon"
          width={18}
          height={18}
          className="mr-2"
        />
        {!showFullMap ? 'Show Map' : 'Show List'}
      </button>
    </div>
  );
};
