import { LoadingSpinner } from '../../../shared-components/LoadingSpinner/LoadingSpinner';

interface LoadMoreButtonProps {
  handleLoadMore: () => void;
  isLoadingMoreAttractions: boolean;
}

export const LoadMoreButton: React.FC<LoadMoreButtonProps> = ({
  handleLoadMore,
  isLoadingMoreAttractions
}) => (
  <div className="flex justify-center md:pt-10 pb-[3.5rem] pt-[2.5rem] sm:py-12 z-[39] relative bg-white">
    <button
      className="bg-primary hover:bg-[#2E3D7A] active:bg-[#2E3D7A] text-white rounded-full px-6 py-2"
      onClick={handleLoadMore}
      disabled={isLoadingMoreAttractions}
    >
      {isLoadingMoreAttractions ? (
        <div className="flex items-center">
          <LoadingSpinner className="w-4 h-4 mr-2 inline-block border-white" />
          <p>Loading...</p>
        </div>
      ) : (
        'Load More'
      )}
    </button>
  </div>
);
