import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { LinkStyle } from './footer';
import { SocialMediaIcons } from '../../shared-components/SocialMediaIcons';
import { useMediaQuery } from '../../hooks/mediaQuery';
import { toast } from 'react-toastify';

interface StickyFooterBarProps {
  isMobileScreen: boolean;
  toggleQuickLink: () => void;
  openQuickLinks: boolean;
  showNormalFooter: boolean;
}

export const StickyFooterBar = ({
  isMobileScreen,
  toggleQuickLink,
  openQuickLinks,
  showNormalFooter
}: StickyFooterBarProps) => {
  const router = useRouter();

  const isMobile = useMediaQuery(500);

  const handleCallClick = () => {
    if (isMobile) {
      // Mobile behavior: initiate a call
      window.location.href = 'tel:+1-888-961-6584';
    } else {
      // Web behavior: copy to clipboard and show toast
      navigator.clipboard
        .writeText('+1-888-961-6584')
        .then(() => {
          toast.success('Copied');
        })
        .catch((err: Error) => {
          console.error('Failed to copy: ', err);
        });
    }
  };

  return (
    <div
      className={`h-[45px] ${
        openQuickLinks || showNormalFooter ? 'px-0' : 'px-[5%]'
      } pt-10 sm:pt-[30px] xl:pt-[30px] pb-[30px] border-t-2 text-[#666] flex justify-center xl:justify-between text-center items-center`}
    >
      <div className="flex w-full flex-col sm:flex-row-reverse justify-between xl:hidden gap-3 pb-4 sm:pb-0 items-center">
        {!isMobileScreen && !showNormalFooter && (
          <button
            className="flex  md:text-base items-center cursor-pointer bg-[#1F2C5B] text-white py-2.5 px-6 rounded-[24px] h-[38px] hover:bg-[#2E3D7A] active:bg-[#2E3D7A]"
            onClick={toggleQuickLink}
          >
            Menu
            <Image
              src="/white-down-arrow.svg"
              alt="white down arrow"
              width={20}
              height={20}
              className={`${openQuickLinks ? 'rotate-180' : 'rotate-0'} ml-2.5`}
            />
          </button>
        )}

        <div className="flex gap-4 justify-around text-[12px]">
          <div className="flex items-center gap-[8px]">
            <Image
              src={'/rating-star-yellow.svg'}
              width={17}
              height={17}
              alt="rating star"
            />
            <p
              onClick={() => router.push('/reviews')}
              className="text-[14px] text-black13 cursor-pointer"
            >
              4.8 rating | 5,753 reviews
            </p>
          </div>
        </div>
        <div>
          <p className="text-xs mmsm:text-[13px] whitespace-nowrap flex justify-center">
            © 2025 See Sight Tours.&nbsp;
            <span className="sm:hidden md:flex">All Rights Reserved.</span>
          </p>
        </div>
      </div>
      <div className="hidden xl:flex justify-between text-[12px] gap-8">
        {['/privacy-policy', '/terms-and-conditions'].map((path, index) => (
          <Link key={index} href={path}>
            <LinkStyle
              className="text-black hover:text-blue-400 hover:underline text-xs mmsm:text-[13px]"
              isactive={(router.asPath === path).toString()}
            >
              {path === '/privacy-policy'
                ? 'Privacy Policy'
                : 'Terms and Conditions'}
            </LinkStyle>
          </Link>
        ))}
      </div>
      <div>
        <p className="hidden xl:flex text-xs mmsm:text-[13px]">
          © 2025 See Sight Tours. All Rights Reserved.
        </p>
      </div>
      <div className="sm:hidden xl:flex">
        {!isMobileScreen && (
          <div className="flex gap-8">
            <div className="flex items-center gap-[8px]">
              <Image
                src={'/rating-star-yellow.svg'}
                width={17}
                height={17}
                alt="rating star"
              />
              <p
                onClick={() => router.push('/reviews')}
                className="text-[16px] text-black13 cursor-pointer"
              >
                4.8 rating | 5,753 reviews
              </p>
            </div>
            <div className="flex items-center gap-[8px]">
              <Image
                src="/blue-phone-icon.svg"
                width={17}
                height={17}
                alt="rating star"
              />
              <p
                className="text-[16px] text-[#4285F4] cursor-pointer"
                onClick={handleCallClick}
              >
                1-888-961-6584
              </p>
            </div>
            {!showNormalFooter && (
              <button
                className="flex  md:text-base items-center cursor-pointer bg-[#1F2C5B] text-white py-2.5 px-6 rounded-[24px] h-[38px] hover:bg-[#2E3D7A] active:bg-[#2E3D7A]"
                onClick={toggleQuickLink}
              >
                Menu
                <Image
                  src="/white-down-arrow.svg"
                  alt="white down arrow"
                  width={20}
                  height={20}
                  className={`${
                    openQuickLinks ? 'rotate-180' : 'rotate-0'
                  } ml-2.5`}
                />
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
